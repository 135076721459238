import { APP_IMG, APP_URL, IS_NO_ROBOT_INDEX } from 'src/constants/index';
import { IMeta } from 'src/interface/section';
import { NextRouter, useRouter } from 'next/router';
import { splitRote } from 'src/utils/helper';
import { controller } from 'src/switch-themes';
import { IMapArticle } from 'src/utils/meta/snippets/articles/content';
import { IMetaTag } from 'src/interface/service';

type Props = {
  data?: any;
};
const { website, socials, title, description, name, image, keyword, locale } = controller;

export const pageNextSEO = ({ data }: Props) => {

  const router: NextRouter = useRouter();
  const themePage = splitRote(router);
  switch (router.pathname) {
    case `${themePage || '/'}`:
      return homepage(data);
    case `${themePage}/[cid]`:
      return category(data);
    case `${themePage}/[cid]/[id]`:
      return detail(data);
    case `${themePage}/tags/[id]`:
      return tags(data);
    case `${themePage}/search`:
      return search(data);
    case `${themePage}/lottery`:
      return lottery(data);
    case `${themePage}/lottery/[id]`:
      return lotteryContents(data);
    case `${themePage}/video`:
      return video(data);
    case `${themePage}/video/[cid]`:
      return videoCategory(data);
    case `${themePage}/video/tags/[id]`:
      return videoTags(data);
    case `${themePage}/video/[cid]/[id]`:
      return videoDetail(data);
    case `${themePage}/tv-program`:
      return videoGallery(data);
    case `${themePage}/tv-program/[id]`:
      return videoGalleryDetail(data);
    case `${themePage}/galleries/[id]`:
      return galleryCategory(data, router);
    case `${themePage}/galleries/tags/[id]`:
      return galleryTagsDetail(data);
    case `/specials-002`:
      return specials002(data);
    default:
      return null;
  }
};

type PropsSEO = {
  metaTag?: IMetaTag;
  meta?: any;
};

const homepage = ({ metaTag }: PropsSEO) => {
  const textTitle: string = typeof metaTag?.title === 'undefined' ? title : metaTag.title || title;
  const textDescription: string = typeof metaTag?.description === 'undefined' ? description : metaTag.description || description;
  const textKeyword: string = typeof metaTag?.keywords === 'undefined' ? keyword : metaTag.keywords || keyword;

  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    canonical: website,
    openGraph: {
      type: 'website',
      locale: locale,
      url: website,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      images: [
        {
          url: image.default,
          width: 1920,
          height: 1080,
          alt: name.en
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: image.default
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: image.default
      }
    ]
  };
};

const category = ({ meta }: PropsSEO) => {
  const {
    title,
    description,
    category: { pathUrl, seoTitle, seoDescription, seoKeywords },
    locale
  } = controller;

  const baseUrl: string = pathUrl(meta) || '';
  const textTitle: string = meta?.title || seoTitle(meta) || title;
  const textDescription: string = meta?.description || seoDescription(meta) || description;
  const textKeyword: string = meta?.keyword || seoKeywords(meta) || keyword;

  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: `${textTitle}`,
    description: textDescription,
    canonical: baseUrl,
    openGraph: {
      type: 'website',
      locale: locale,
      url: baseUrl,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      images: [
        {
          url: image.default,
          width: 1920,
          height: 1080,
          alt: name.en
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: image.default
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: image.default
      }
    ]
  };
};

const detail = (data: any) => {
  const { article } = data || {};

  const _article: IMapArticle = {
    ...article,
    title: article?.meta?.title || title,
    description: article?.meta?.description || description,
    keyword: article?.meta?.keyword || keyword,
    imageUrl: article?.meta?.og_social_image || image.default
  };
  const baseUrl: string = `${APP_URL}${_article.link}`;
  const textTitle: string = _article.title || title;
  const textDescription: string = _article.description || description;
  const textKeyword: string = _article.keyword || keyword;

  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    canonical: _article.canonical,
    openGraph: {
      type: 'article',
      locale: locale,
      url: baseUrl,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      article: {
        publishedTime: _article.published_at,
        modifiedTime: _article.updated_at,
        section: _article.section?.th,
        authors: [`${socials.facebook}`],
        tags: _article.tags
      },
      images: [
        {
          url: _article.imageUrl,
          width: 700,
          height: 395,
          alt: textTitle
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: _article.imageUrl || image.default
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: _article.imageUrl || image.default
      }
    ]
  };
};
const tags = (data: any) => {
  const { meta } = data || {};
  const { tagText } = data || {};
  const {
    tags: { seoTitle, seoDescription },
    locale
  } = controller;

  const _article: IMeta = {
    ...meta,
    title: tagText ? seoTitle(tagText) || title : title,
    description: tagText ? seoDescription(tagText) || description : description,
    keyword: `${tagText}, tags`
  };
  const baseUrl: string = `${APP_URL}/tags/${tagText}`;
  const textTitle: string = _article.title || title;
  const textDescription: string = _article.description || description;
  const textKeyword: string = _article.keyword || keyword;
  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    canonical: encodeURI(baseUrl),
    openGraph: {
      type: 'website',
      locale: locale,
      url: baseUrl,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      images: [
        {
          url: image.default,
          width: 1920,
          height: 1080,
          alt: name.en
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: image.default
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: image.default
      }
    ]
  };
};
const search = (data: any) => {
  const { meta } = data || {};
  const { searchText } = data || {};
  const {
    search: { seoTitle, seoDescription, seoKeywords },
    locale
  } = controller;
  const _article: IMeta = {
    ...meta,
    title: seoTitle(searchText) || title,
    description: seoDescription(searchText) || description,
    keyword: seoKeywords(searchText) || keyword
  };
  const baseUrl: string = `${APP_URL}/search?q=${searchText}`;
  const textTitle: string = _article.title || title;
  const textDescription: string = _article.description || description;
  const textKeyword: string = _article.keyword || keyword;
  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    canonical: baseUrl,
    openGraph: {
      type: 'website',
      locale: locale,
      url: baseUrl,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      images: [
        {
          url: image.default,
          width: 1920,
          height: 1080,
          alt: name.en
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: image.default
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: image.default
      }
    ]
  };
};
const lottery = (data: any) => {
  const { meta } = data || {};
  const {
    lotteryCategory: { seoTitle, seoDescription, seoKeywords },
    locale
  } = controller;

  const _article: IMeta = { ...meta, title: meta?.title || title, description: meta?.description || description, keyword: meta?.keyword || keyword };
  const baseUrl: string = `${APP_URL}/lottery`;
  const textTitle: string = _article ? seoTitle(_article) || title : title;
  const textDescription: string = _article ? seoDescription(_article) || description : description;
  const textKeyword: string = _article ? seoKeywords(_article) || keyword : keyword;
  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    canonical: baseUrl,
    openGraph: {
      type: 'website',
      locale: locale,
      url: baseUrl,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      images: [
        {
          url: image.default,
          width: 1920,
          height: 1080,
          alt: name.en
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: image.default
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: image.default
      }
    ]
  };
};
const lotteryContents = (data: any) => {
  const { meta, article } = data || {};
  const {
    lotteryContents: { seoTitle, seoDescription, seoKeywords },
    locale
  } = controller;


  const _article: IMeta = { ...meta, title: meta?.title || title, description: meta?.description || description, keyword: meta?.keyword || keyword };

  const baseUrl: string = `${APP_URL}/lottery/${article.dateDefault}`;
  const textTitle: string = _article ? seoTitle(_article) || title : title;
  const textDescription: string = _article ? seoDescription(_article) || description : description;
  const textKeyword: string = _article ? seoKeywords(_article) || keyword : keyword;
  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    canonical: baseUrl,
    openGraph: {
      type: 'article',
      locale: locale,
      url: baseUrl,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      images: [
        {
          url: `${APP_IMG}${data?.article?.image}`,
          width: 1920,
          height: 1080,
          alt: name.en
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: `${APP_IMG}${article?.image}`
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: `${APP_IMG}${article?.image}`
      }
    ]
  };
};
const video = (data: any) => {
  const { metaTags } = data || {};
  const textTitle: string = typeof metaTags?.title === 'undefined' ? title : metaTags.title || title;
  const textDescription: string = typeof metaTags?.description === 'undefined' ? description : metaTags.description || description;
  const textKeyword: string = typeof metaTags?.keywords === 'undefined' ? keyword : metaTags.keywords || keyword;
  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    canonical: website,
    openGraph: {
      type: 'website',
      locale: locale,
      url: website,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      images: [
        {
          url: image.default,
          width: 1920,
          height: 1080,
          alt: name.en
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: image.default
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: image.default
      }
    ]
  };
};

const videoCategory = (data: any) => {
  const { meta } = data || {};
  const {
    videoCategory: { seoTitle, seoDescription, seoKeywords },
    locale
  } = controller;
  const _article: IMeta = { ...meta, title: meta?.title || title, description: meta?.description || description, keyword: meta?.keyword || keyword };
  const baseUrl: string = `${APP_URL}/video/${_article.category?.en}`;
  const textTitle: string = _article ? seoTitle(_article) || title : title;
  const textDescription: string = _article ? seoDescription(_article) || description : description;
  const textKeyword: string = _article ? seoKeywords(_article) || keyword : keyword;
  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    canonical: baseUrl,
    openGraph: {
      type: 'website',
      locale: locale,
      url: baseUrl,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      images: [
        {
          url: image.default,
          width: 1920,
          height: 1080,
          alt: name.en
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: image.default
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: image.default
      }
    ]
  };
};
const videoTags = (data: any) => {
  const { meta } = data || {};
  const { tagText } = data || {};
  const {
    tags: { seoTitle, seoDescription },
    locale
  } = controller;

  const _article: IMeta = {
    ...meta,
    title: tagText ? seoTitle(tagText) || title : title,
    description: tagText ? seoDescription(tagText) || description : description,
    keyword: `${tagText}, tags`
  };
  const baseUrl: string = `${APP_URL}/video/tags/${tagText}`;
  const textTitle: string = _article.title || title;
  const textDescription: string = _article.description || description;
  const textKeyword: string = _article.keyword || keyword;
  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    canonical: baseUrl,
    openGraph: {
      type: 'website',
      locale: locale,
      url: baseUrl,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      images: [
        {
          url: image.default,
          width: 1920,
          height: 1080,
          alt: name.en
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: image.default
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: image.default
      }
    ]
  };
};
const videoDetail = (data: any) => {
  const { article, meta } = data || {};
  const _article: IMapArticle = {
    ...article,
    title: meta?.title || title,
    description: meta?.description || description,
    keyword: meta?.keyword || keyword,
    imageUrl: meta?.og_social_image || image.default
  };
  const baseUrl: string = `${APP_URL}${_article.link}`;
  const textTitle: string = _article.title || title;
  const textDescription: string = _article.description || description;
  const textKeyword: string = _article.keyword || keyword;

  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    canonical: `${APP_URL}${_article.link}`,
    openGraph: {
      type: 'article',
      locale: locale,
      url: baseUrl,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      article: {
        publishedTime: _article.published_at,
        modifiedTime: _article.updated_at,
        section: _article.section?.th,
        authors: [`${socials.facebook}`],
        tags: _article.tags
      },
      images: [
        {
          url: `${APP_IMG}${_article.imageUrl}`,
          width: 700,
          height: 395,
          alt: textTitle
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: `${APP_IMG}${_article.imageUrl}`
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: `${APP_IMG}${_article.imageUrl}`
      }
    ]
  };
};
const videoGallery = (data: any) => {
  const textTitle: string = data?.meta?.title || title;
  const textDescription: string = data?.meta?.description || description;
  const textKeyword: string = data?.meta?.keyword || keyword;
  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    canonical: website,
    openGraph: {
      type: 'website',
      locale: locale,
      url: website,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      images: [
        {
          url: data?.meta?.image === '/uploads/images/categories/' ? image.default : `${APP_IMG}${data?.meta?.image || '/default.jpg'}`,
          width: 1920,
          height: 1080,
          alt: name.en
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: data?.meta?.image === '/uploads/images/categories/' ? image.default : `${APP_IMG}${data?.meta?.image || '/default.jpg'}`
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: data?.meta?.image === '/uploads/images/categories/' ? image.default : `${APP_IMG}${data?.meta?.image || '/default.jpg'}`
      }
    ]
  };
};
const videoGalleryDetail = (data: any) => {
  const baseUrl: string = `${APP_URL}${data.article.link || ''}`;
  const textTitle: string = data?.meta?.title || title;
  const textDescription: string = data?.meta?.description || description;
  const textKeyword: string = data?.meta?.keyword || keyword;
  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    canonical: baseUrl,
    openGraph: {
      type: 'article',
      locale: locale,
      url: baseUrl,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      article: {
        publishedTime: data?.article?.published_at,
        modifiedTime: data?.article?.updated_at,
        section: data?.article?.subCategory?.th,
        authors: [`${socials.facebook}`],
        tags: data?.article?.tags
      },
      images: [
        {
          url: `${APP_IMG}${data?.meta?.og_social_image || '/default.jpg'}`,
          width: 700,
          height: 395,
          alt: textTitle
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      },
      {
        property: 'twitter:url',
        content: baseUrl
      },
      {
        property: 'twitter:title',
        content: textTitle
      },
      {
        property: 'twitter:description',
        content: textDescription
      },
      {
        property: 'twitter:image',
        content: `${APP_IMG}${data?.meta?.og_social_image || '/default.jpg'}`
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: `${APP_IMG}${data?.meta?.og_social_image || '/default.jpg'}`
      }
    ]
  };
};
const galleryCategory = (data: any, router: NextRouter) => {
  const { article } = data || {};
  const _article: IMapArticle = {
    ...article,
    title: article?.title || title,
    description: article?.description || description,
    keyword: article?.keyword || keyword
  };
  const baseUrl: string = `${APP_URL}${router.asPath}`;
  const textTitle: string = _article.title || title;
  const textDescription: string = _article.description || description;
  const textKeyword: string = _article.keyword || keyword;
  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    openGraph: {
      type: 'article',
      locale: locale,
      url: baseUrl,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      article: {
        publishedTime: _article.published_at,
        modifiedTime: _article.updated_at,
        section: _article.section?.th,
        authors: [`${socials.facebook}`],
        tags: _article.tags
      },
      images: [
        {
          url: `${APP_IMG}${_article.image}`,
          width: 700,
          height: 395,
          alt: textTitle
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      }
    ]
  };
};
const galleryTagsDetail = (data: any) => {
  const { meta } = data || {};
  const { tagText } = data || {};
  const {
    galleriesTags: { seoTitle, seoDescription },
    locale
  } = controller;
  const _article: IMeta = {
    ...meta,
    title: tagText ? seoTitle(tagText) || title : title,
    description: tagText ? seoDescription(tagText) || description : description,
    keyword: `${tagText}, tags`
  };
  const baseUrl: string = `${APP_URL}/galleries/tags/${tagText}`;
  const textTitle: string = _article.title || title;
  const textDescription: string = _article.description || description;
  const textKeyword: string = _article.keyword || keyword;
  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title: textTitle,
    description: textDescription,
    openGraph: {
      type: 'article',
      locale: locale,
      url: baseUrl,
      title: textTitle,
      description: textDescription,
      site_name: name.en,
      images: [
        {
          url: image.default,
          width: 1920,
          height: 1080,
          alt: name.en
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: textKeyword
      }
    ]
  };
};
const specials002 = (data: any) => {
  const { title, description, logoApec, keywords } = data?.heading || {};
  const baseUrl: string = `${APP_URL}/specials-002`;
  return {
    noindex: IS_NO_ROBOT_INDEX,
    nofollow: IS_NO_ROBOT_INDEX,
    title,
    description,
    canonical: baseUrl,
    openGraph: {
      type: 'website',
      locale: locale,
      url: baseUrl,
      title,
      description,
      site_name: name.en,
      images: [
        {
          url: image.default,
          width: 1920,
          height: 1080,
          alt: name.en
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'keywords',
        content: keywords
      },
      {
        property: 'twitter:url',
        content: website
      },
      {
        property: 'twitter:title',
        content: title
      },
      {
        property: 'twitter:description',
        content: description
      },
      {
        property: 'twitter:image',
        content: logoApec || image.default
      }
    ],
    additionalLinkTags: [
      {
        rel: 'image_src',
        href: logoApec || image.default
      }
    ]
  };
};
